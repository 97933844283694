import React from 'react';
import './Footer.scss'; // You'll need to create this file for styling
import { Link, useLocation } from 'react-router-dom'; // Import useLocation

const Footer = () => {
  const location = useLocation(); // Get the current location
  const hiddenRoutes = ['/LoginPage', '/dashboard'];
  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }
  return (
    <footer className="footer">
      <div className="footer-top">
        <a href="#top" className="back-to-top">กลับสู่ด้านบน ↑</a>
      </div>

      <div className="footer-columns">
        <div className="footer-column">
          <h3>ติดต่อเรา</h3>
          <p>
            1023 อาคาร ที.พี.เอส บิลดิ้ง ชั้น 5
          </p>
          <div className="contact-info">
            <p>ถนนพัฒนาการ สวนหลวง กรุงเทพฯ 10250</p>
            <p>เบอร์ติดต่อ : 02-717-8180</p>
          </div>
        </div>

        <div className="footer-column">
          <h3>Shop</h3>
          <ul>
            <Link to="/productslist">ผลิตภัณฑ์ใหม่</Link>
          </ul>
        </div>



        <div className="footer-column">
          <h3>ติดตามข่าวสาร</h3>
          <p>ส่งอีเมลของคุณ เพื่อรับข่าวใหม่ๆ ของเรา</p>
          <form className="subscribe-form">
            <input type="email" placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="social-links">
          <a>Facebook</a>
          <a>Instagram</a>
          <a>Twitter</a>
        </div>
        <p>© DOUBLEPLALOMA • All rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom'; // Import useLocation
import FilterLink from '../presets/FilterLink';
import './Navbar.scss';

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [isSlidingUp, setIsSlidingUp] = useState(false); // Track sliding up
  const [isTop, setIsTop] = useState(true);
  const submenuRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false); // Track if menu is fully open 
  const location = useLocation(); // Get the current location
  const [isAnimating, setIsAnimating] = useState(false);

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [scrollPos, setScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const [activeMobileMenu, setActiveMobileMenu] = useState(null);

  const MEN_ITEMS = [
    { name: 'เสื้อยืด', pro_type: 'ผู้ชาย', prosty_id: 11, procata_id: 1 },
    { name: 'เสื้อกล้าม', pro_type: 'ผู้ชาย', prosty_id: 12, procata_id: 1 },
  ];

  const WOMEN_ITEMS = [
    { name: 'เสื้อยืด', pro_type: 'ผู้หญิง', prosty_id: 11, procata_id: 2 },
    { name: 'เสื้อกล้าม', pro_type: 'ผู้หญิง', prosty_id: 12, procata_id: 2 },
  ];

  // Handle scroll to make the navbar transparent at the top
  const handleScroll = useCallback(() => {
    const currentScrollPos = window.pageYOffset;
    const isMobile = window.innerWidth <= 768; // Detect mobile screen size

    // If not on the home page, keep the navbar in scrolled mode
    if (location.pathname !== '/') {
      setIsTop(false);
    } else {
      if (currentScrollPos === 0) {
        if (!menuOpen) {
          setIsTop(true); // Transparent navbar at the top on the home page
        } else {
          setIsTop(false); // Transparent navbar at the top on the home page

        }
        if (isMobile) {
          setVisible(true); // Ensure the navbar remains visible on mobile when at the top
        }
      } else {
        setIsTop(false); // Scrolled mode when the user scrolls down
      }
    }

    // Handle mobile visibility logic
    if (isMobile && location.pathname === '/') {
      if (currentScrollPos < scrollPos) {
        setVisible(true); // Scrolling up, show the navbar
      } else if (currentScrollPos > scrollPos) {
        setVisible(false); // Scrolling down, hide the navbar
      }
    }

    // Update the scroll position
    setScrollPos(currentScrollPos);
  }, [scrollPos, location.pathname]);

  useEffect(() => {
    if (location.pathname !== '/') {
      setIsTop(false);
    }
  });


  // Toggle the selected menu with slide up/down animation
  const toggleMenu = (menu) => {
    if (window.innerWidth > 768) {
      // Only handle for desktop (screen width > 768px)
      if (isAnimating) return;

      if (activeMenu === menu) {
        setIsSlidingUp(true);
        setIsAnimating(true);

        setTimeout(() => {
          setActiveMenu(null);
          setMenuOpen(false);
          setIsAnimating(false);
          if (window.pageYOffset === 0) {
            if (location.pathname !== '/') {
              setIsTop(false);
            } else {
              setIsTop(true);
            }

          }
        }, 300);
      } else {
        setIsSlidingUp(true);
        setIsAnimating(true);

        setTimeout(() => {
          setActiveMenu(menu);
          setIsSlidingUp(false);
          setMenuOpen(true);
          setIsAnimating(false);
          setIsTop(false); // Switch to scrolled mode
        }, 300);
      }
    }
  };


  // Handle clicking outside the submenu to close it
  const handleClickOutside = (event) => {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      if (menuOpen) {
        setIsSlidingUp(true);

        // Close the menu
        setTimeout(() => {
          setActiveMenu(null);
          setMenuOpen(false);

          // Check if we are at the top of the page
          if (window.pageYOffset === 0 && location.pathname === '/') {
            // If on the homepage and at the top, switch to "top" mode
            setIsTop(true);
          } else {
            // Keep it in "scrolled" mode if not at the top
            setIsTop(false);
          }
        }, 300); // Adjust the delay if needed
      }
    }
  };

  const toggleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
    setMenuOpen(!menuOpen);
  };

  const toggleMobileMenu = (menu) => {
    if (window.innerWidth <= 768) {
      if (activeMobileMenu === menu) {
        setActiveMobileMenu(null); // Close the current submenu
      } else {
        setActiveMobileMenu(menu); // Open the selected submenu
      }
    }
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location.pathname, handleScroll]); // Listen to changes in the route

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        // Reset mobile menu state variables
        setHamburgerOpen(false);
        setMenuOpen(false);
        setActiveMobileMenu(null);
      } else {
        // Reset desktop menu state variables
        setActiveMenu(null);
        setMenuOpen(false);
        setIsSlidingUp(false);
        setIsAnimating(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuOpen, hamburgerOpen]);


  const hiddenRoutes = ['/LoginPage', '/dashboard'];
  if (hiddenRoutes.includes(location.pathname)) {
    return null;
  }


  return (
    <div>
      <nav className={`navbar ${isTop ? '' : 'scrolled'} ${hamburgerOpen ? 'menu-open' : ''}`}>
        <div className="navbar-content">
          <div className="left-menu">
            <Link to='/'>
              <img src="/PlalomaLogo.png" alt="Paloma Logo" className="logo" />
            </Link>
            <ul className="nav-links">
              <li className="menu-item">
                <FilterLink clearFilters={true} to="/productslist">ผลิตภัณฑ์ใหม่</FilterLink>
              </li>
              <li className={`menu-item ${activeMenu === 'men' ? 'disabled' : ''}`} onClick={() => toggleMenu('men')}>
                <a>ผู้ชาย</a>
                <span className={`caret ${activeMenu === 'men' ? 'rotate' : ''}`}>^</span>
              </li>
              <li className={`menu-item ${activeMenu === 'women' ? 'disabled' : ''}`} onClick={() => toggleMenu('women')}>
                <a>ผู้หญิง</a>
                <span className={`caret ${activeMenu === 'women' ? 'rotate' : ''}`}>^</span>
              </li>
            </ul>
            <div className={`hamburger-menu ${hamburgerOpen ? 'open' : ''}`} onClick={toggleHamburger}>
              <div className="burger-line"></div>
              <div className="burger-line"></div>
              <div className="burger-line"></div>
            </div>
            {/* Mobile Menu */}
            <div className={`mobile-menu ${hamburgerOpen ? 'slide-in' : ''}`}>
              <ul className="mobile-nav-links">
                <li className="menu-item">
                  <Link to="/productslist">ผลิตภัณฑ์ใหม่</Link>
                </li>
                {/* Men Section */}
                <li className="menu-item">
                  <div onClick={() => toggleMobileMenu('men')} className="menu-title">
                    <p>ผู้ชาย</p>
                    <span className={`caret ${activeMobileMenu === 'men' ? 'rotate' : ''}`}>^</span>
                  </div>
                  {/* Submenu for Men */}
                  <ul className={`submenu ${activeMobileMenu === 'men' ? 'show' : ''}`}>
                    {MEN_ITEMS.map((items) => (
                      <div key={items.name} >
                        <FilterLink
                          to={`/productslist?category=${items.pro_type}?title=${items.name}&cateid=${items.procata_id}`}
                          selectedStyles={[{ prosty_id: `${items.prosty_id}`, prosty_name: `${items.name}` }]}  // Use the style ID
                        >
                          <p>{items.name}</p>
                        </FilterLink>
                      </div>
                    ))}
                  </ul>
                </li>

                {/* Women Section */}
                <li className="menu-item">
                  <div onClick={() => toggleMobileMenu('women')} className="menu-title">
                    <a>ผู้หญิง</a>
                    <span className={`caret ${activeMobileMenu === 'women' ? 'rotate' : ''}`}>^</span>
                  </div>
                  <ul className={`submenu ${activeMobileMenu === 'women' ? 'show' : ''}`}>
                    {WOMEN_ITEMS.map((items) => (
                      <div key={items.name} >
                        <FilterLink
                          to={`/productslist?category=${items.pro_type}?title=${items.name}&cateid=${items.procata_id}`}
                          selectedStyles={[{ prosty_id: `${items.prosty_id}`, prosty_name: `${items.name}` }]}  // Use the style ID
                        >
                          <p>{items.name}</p>
                        </FilterLink>
                      </div>
                    ))}
                  </ul>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </nav>

      {/* Submenus PC */}
      <div ref={submenuRef}>
        {activeMenu === 'men' && (
          <div className={`full-width-menu ${isSlidingUp ? 'slideUp' : 'slideDown'}`}>
            <div className="menu-content-items">
              <h2>เสื้อยืด</h2>
              <div>
                {MEN_ITEMS.map((items) => (
                  <div key={items.name} >
                    <FilterLink
                      to={`/productslist?category=${items.pro_type}?title=${items.name}&cateid=${items.procata_id}`}
                      selectedStyles={[{ prosty_id: `${items.prosty_id}`, prosty_name: `${items.name}` }]}  // Use the style ID
                    >
                      <p>{items.name}</p>
                    </FilterLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        {activeMenu === 'women' && (
          <div className={`full-width-menu ${isSlidingUp ? 'slideUp' : 'slideDown'}`}>
            <div className="menu-content-items">
              <h2>เสื้อยืด</h2>
              {WOMEN_ITEMS.map((items) => (
                <div key={items.name} >
                  <FilterLink
                    to={`/productslist?category=${items.pro_type}?title=${items.name}&cateid=${items.procata_id}`}
                    selectedStyles={[{ prosty_id: `${items.prosty_id}`, prosty_name: `${items.name}` }]}  // Use the style ID
                  >
                    <p>{items.name}</p>
                  </FilterLink>
                </div>
              ))}
            </div>
          </div>
        )}

      </div>
    </div>
  );
};

export default Navbar;